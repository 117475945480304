import React, { useState } from 'react';

import { clsx } from '../../utils/utils';
import * as styles from './Accordion.module.scss';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  startsOpen?: boolean;
  className?: string;
  withBackgroundColor?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const Accordion = ({
  title,
  children,
  startsOpen,
  className,
  withBackgroundColor,
  onOpen,
  onClose,
}: AccordionProps): React.ReactElement => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(!!startsOpen);
  const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);

  const bodyMaxHeight = isAccordionOpen && bodyRef ? bodyRef.scrollHeight : 0;

  return (
    <div
      className={clsx(
        styles.accordion,
        withBackgroundColor && styles.withBackgroundColor,
        isAccordionOpen && styles.accordionOpen,
        className,
      )}
    >
      <div className={styles.accordionBox}>
        <button
          className={styles.titleBox}
          aria-expanded={isAccordionOpen}
          aria-disabled="false"
          role="button"
          tabIndex={0}
          onClick={() => {
            setIsAccordionOpen(!isAccordionOpen);
            if (isAccordionOpen) {
              if (onClose) {
                onClose();
              }
            } else {
              if (onOpen) {
                onOpen();
              }
            }
          }}
        >
          <div className={styles.titleContainer}>
            <span className={styles.title}>{title}</span>
          </div>
          <div className={styles.iconContainer}>
            <div className={styles.iconVerticalLine}></div>
            <div className={styles.iconHorizontalLine}></div>
          </div>
        </button>
        <div
          ref={newRef => {
            setBodyRef(newRef);
          }}
          className={styles.body}
          style={{ maxHeight: bodyMaxHeight }}
          role="region"
          aria-hidden={!isAccordionOpen}
        >
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
